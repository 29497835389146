<div class="banner">
  <a class="bannerescritorio" [routerLink]="['/inicio']"><img class="bannerone" src="/assets/bannerandecorp 6.png" alt=""></a><a class="bannerescritorio" href="https://www.makchile.cl/"><img class="bannertwo" src="/assets/MAKCHILEVECTOR.png" alt=""></a>
  <a class="bannermovil" [routerLink]="['/inicio']"><img src="/assets/bannerandecorpphone1.jpg" alt=""></a>
</div>


<nav class="navbar navbar-expand-xl navbar-light bg-light sticky-top">
  <a class="navbar-brand" [routerLink]="['/inicio']">
    <img src="/assets/Recurso-2.png" width="168" height="31" class="d-inline-block align-top" alt="" loading="lazy">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown" >
    <ul class="navbar-nav mx-auto">
      
      <li class="nav-item dropdown" >
        <a [routerLink]="['/nosotros']" class="nav-link" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
          Nosotros
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" >
          <a class="dropdown-item" [routerLink]="['/quienessomos']">Quiénes somos</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/politicadecalidad']">Política de calidad</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/satisfaccionalcliente']">Satisfacción al cliente</a>
        </div>
      </li>
    

      <li class="nav-item dropdown">
        <a [routerLink]="['/nuestrosproductos']" class="nav-link" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Nuestros productos
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/andamiosfront']">Andamios Front</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/andamiosmulti-front']">Andamios Multi-Front</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/componentescomunes']">Componentes Comunes</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/aplicaciones']">Aplicaciones</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/revista']">Catálogo</a>
        </div>
      </li>

      <li class="nav-item dropdown">
        <a [routerLink]="['/proyectos']" class="nav-link" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Proyectos
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/sistemafront']">Sistema Front</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/sistemamultifront']">Sistema Multi-Front</a>
        </div>
      </li>

 
      <li class="nav-item dropdown">
        <a [routerLink]="['/certificaciones-home']" class="nav-link" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Certificaciones
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/certificaciones']">Certificaciones</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/seguridad']">Seguridad y Flexibilidad</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/productoscertificados']">Certificados de Cálculo</a>
        </div>
      </li>


      <li class="nav-item dropdown">
        <a [routerLink]="['/sucursales']" class="nav-link" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Sucursales
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/iquique']">Andecorp Iquique</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/antofagasta']">Andecorp Antofagasta</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/valparaiso']">Andecorp Valparaiso</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/santiago']">Andecorp Santiago</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/talca']">Andecorp Talca</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/concepcion']">Andecorp Concepción</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/puertomontt']">Andecorp Puerto Montt</a>
        </div>
      </li>

      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/contacto']">Contacto</a>
      </li>
      <button type="button" class="btnandecorp mx-auto" [routerLink]="['/proximamente']">Mi Andecorp</button>
    </ul>
  </div>
</nav>

  
  <router-outlet></router-outlet>

  <div class="pie">
    <div class="pie2">
    <div class="logoande animate__animated animate__pulse animate__infinite animate__slow">
      <img src="/assets/Andecorp-logo.png" alt="">
    </div>
    <div class="contenedorinfo">
    <div class="info">
      <ul><h6>Información</h6>
        <li><a [routerLink]="['/quienessomos']">Quiénes somos</a></li>
        <li><a [routerLink]="['/nuestrosproductos']">Nuestros productos</a></li>
        <li><a [routerLink]="['/sucursales']">Sucursales</a></li>
        <li><a [routerLink]="['/certificaciones']">Certificaciones</a></li>
        <li><a [routerLink]="['/contacto']">Contacto</a></li>
      </ul>
    </div>
    <div class="info2"> 
      <ul><h6>Sucursales</h6>
        <li><a [routerLink]="['/iquique']">Andecorp Iquique</a></li>
        <li><a [routerLink]="['/antofagasta']">Andecorp Antofagasta</a></li>
        <li><a [routerLink]="['/concepcion']">Andecorp Concepción</a></li>
        <li><a [routerLink]="['/puertomontt']">Andecorp Puerto Mont</a></li>
        <li><a [routerLink]="['/santiago']">Andercop Santiago <br>(Casa matriz)</a></li>
      </ul>
    </div>
    <div class="info3">
      <ul><h6>Proyectos</h6>
        <li>
          <a [routerLink]="['/sistemafront']">Galeria de imágenes</a>
        </li>
      </ul>
    </div>
    <div class="rrss">
      <h6>Síguenos en</h6>
      <div class="iconos">
        <img src="/assets/facebook-square.svg" alt="">
        <img src="/assets/linkedin-brands.svg" alt="">
        <img src="/assets/twitter-square.svg" alt="">
        <img src="/assets/youtube-brands.svg" alt="">
      </div>
      <div class="direccion">
        <img src="/assets/map-marker-alt-solid (2).svg" alt="">
        <a>Senador Jaime Guzmán #141</a>
      </div>
      <div class="fono">
        <img src="/assets/phone-solid.svg" alt="">
        <a>(+56) 22 603 9091  <br>(+56) 22 603 64 11 <br>(+56) 22 603 6395 <br>(+56) 22 603 4388 </a> 
      </div>
      <div class="ventas">
        <img src="/assets/envelope-solid.svg" alt="">
        <a>ventas@andecorp.cl</a>
      </div>
    </div>
    </div>
  </div>

  
  <footer>
    <div class="rrss2">
      <h6>Síguenos en</h6>
      <div class="iconos2">
        <img class="mr-3 ml-4" src="/assets/facebook-square.svg" alt="">
        <img class="mr-3" src="/assets/linkedin-brands.svg" alt="">
        <img class="mr-3" src="/assets/twitter-square.svg" alt="">
        <img class="mr-3" src="/assets/youtube-brands.svg" alt="">
      </div>
    </div>
  </footer>
  </div>





