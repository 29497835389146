import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sistemafront',
  templateUrl: './sistemafront.component.html',
  styleUrls: ['./sistemafront.component.css']
})
export class SistemafrontComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
