import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-antofagasta',
  templateUrl: './antofagasta.component.html',
  styleUrls: ['./antofagasta.component.css']
})
export class AntofagastaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
