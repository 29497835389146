<div class="fondo">
<!-- <div class="menus">
    <a [routerLink]="['/quienessomos']"><div class="one">Quiénes somos</div></a>
    <a [routerLink]="['/politicadecalidad']"><div class="two">Política de calidad</div></a>
    <a [routerLink]="['/satisfaccionalcliente']"><div class="three">Satisfacción al cliente</div></a>
</div> -->
<div class="texts animate__animated animate__backInUp animate__slow">
    <h1>Quiénes somos</h1>
    <p>Andecorp SpA empresa chilena establecida en 1992 y certificada ISO 9000 desde el año 2011, orientada principalmente a la fabricación e importación de equipos de Andamiaje y Apuntalamiento. <br><br> 
        Tiene como misión prestar servicios de arriendo y/o venta, de estos equipos a empresas constructoras, empresas de montaje industrial, empresas mineras, montajes de eventos y otras relacionadas con trabajos en altura, en el mercado nacional y extranjero.</p>
</div>
<div class="imgs">
    <img src="/assets/andamios-01.jpg" alt="">
</div>
</div>