<img class="sucursal" src="/assets/puchuncavi.png" style="width:100%; "  alt="">

<div class="fondo">  
    <div class="texts">
        <h1>Andecorp Puchuncavi</h1>
        <p><b>&bull; Dirección:</b> Pablo Neruda 64 – Puchuncavi <br>
            <b>&bull; Teléfono:</b> (+56) 9 8551 6011 - (+56) 9 8551 6068 <br>
            <b>&bull; Email:</b> puchuncavi@andecorp.cl</p>
            <div class="imagen"><img src="/assets/mapas/puchuncavi2.png" alt=""></div>

            <p class="localidades"><b> VALPARAISO:</b> Zapallar, Papudo, La Ligua, La Cruz, Petorca, Quintero, Cabildom, etc. <br> 
               <!--  <b> VALPARAISO: </b> Puchuncavi. </p> -->
    </div>

    <!--Google map-->
<div id="map-container-google-1" class="z-depth-1-half map-container">
    <iframe src="https://maps.google.com/maps?q=-32.7246185,-71.4181818&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0"
      style="border:0" allowfullscreen></iframe>
  </div>
  
  <!--Google Maps-->


</div>