import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productoscertificados',
  templateUrl: './productoscertificados.component.html',
  styleUrls: ['./productoscertificados.component.css']
})
export class ProductoscertificadosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
