import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuienessomosComponent } from './quienessomos/quienessomos.component';
import { InicioComponent } from './inicio/inicio.component';
import { PoliticadecalidadComponent } from './politicadecalidad/politicadecalidad.component';
import { SatisfaccionalclienteComponent } from './satisfaccionalcliente/satisfaccionalcliente.component';
import { NuestrosproductosComponent } from './nuestrosproductos/nuestrosproductos.component';
import { AndamiosmultiFrontComponent } from './andamiosmulti-front/andamiosmulti-front.component';
import { AndamiosfrontComponent } from './andamiosfront/andamiosfront.component';
import { AlzaprimasComponent } from './alzaprimas/alzaprimas.component';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { IquiqueComponent } from './iquique/iquique.component';
import { AntofagastaComponent } from './antofagasta/antofagasta.component';
import { CertificacionesComponent } from './certificaciones/certificaciones.component';
import { SeguridadComponent } from './seguridad/seguridad.component';
import { SistemafrontComponent } from './sistemafront/sistemafront.component';
import { SistemamultifrontComponent } from './sistemamultifront/sistemamultifront.component';
import { ContactoComponent } from './contacto/contacto.component';
import { SantiagoComponent } from './santiago/santiago.component';
import { ConcepcionComponent } from './concepcion/concepcion.component';
import { PuertomonttComponent } from './puertomontt/puertomontt.component';
import { AplicacionesComponent } from './aplicaciones/aplicaciones.component';
import { ComponentescomunesComponent } from './componentescomunes/componentescomunes.component';
import { GaleriamultifrontComponent } from './galeriamultifront/galeriamultifront.component';
import { GaleriafrontComponent } from './galeriafront/galeriafront.component';
import { ProductoscertificadosComponent } from './productoscertificados/productoscertificados.component';
import { TalcaComponent } from './talca/talca.component';
import { ValparaisoComponent } from './valparaiso/valparaiso.component';
import { ProximamenteComponent } from './proximamente/proximamente.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { CertificacionesHomeComponent } from './certificaciones-home/certificaciones-home.component';
import { RevistaComponent } from './revista/revista.component';


@NgModule({
  declarations: [
    AppComponent,
    QuienessomosComponent,
    InicioComponent,
    PoliticadecalidadComponent,
    SatisfaccionalclienteComponent,
    NuestrosproductosComponent,
    AndamiosmultiFrontComponent,
    AndamiosfrontComponent,
    AlzaprimasComponent,
    SucursalesComponent,
    IquiqueComponent,
    AntofagastaComponent,
    CertificacionesComponent,
    SeguridadComponent,
    SistemafrontComponent,
    SistemamultifrontComponent,
    ContactoComponent,
    SantiagoComponent,
    ConcepcionComponent,
    PuertomonttComponent,
    AplicacionesComponent,
    ComponentescomunesComponent,

    GaleriamultifrontComponent,
    GaleriafrontComponent,
    ProductoscertificadosComponent,
    TalcaComponent,
    ValparaisoComponent,
    ProximamenteComponent,
    NosotrosComponent,
    ProyectosComponent,
    CertificacionesHomeComponent,
    RevistaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
