<div class="fondo">   
    <div class="texts animate__animated animate__backInUp animate__slow">
        <h1>Andamios FRONT®</h1>
        <p>El sistema de andamiajes de fachada Front® cuenta con un conjunto de elementos galvanizados, prefabricados en acero, como marcos, barandas, diagonales, plataformas y una completa gama de componentes auxiliares. <br><br>
            Todos sus componentes de acero son galvanizados en caliente por inmersión aumentando así su vida útil.<br><br>
            Se usa principalmente para trabajos en superficies rectangulares, en las que el acceso no está demasiado restringido.<br><br>
            El andamio de fachada es muy usado en todo tipo de obras civiles, protecciones peatonales, obras de terminación en la industria y como torres rodantes, usadas para trabajos en interiores. <br><br>
            Los andamios de marco son simples y rápidos de montar, proveyendo una superficie de trabajo nivelada, segura y de una gran versatilidad.</p>
    </div>
    <div class="texts2">

        <ul>Componentes
            <li data-toggle="modal" data-target="#ModalPDF"><a style="cursor:pointer;">Sistema de andamios Front®</a></li>
            <li data-toggle="modal" data-target="#ModalPDF2"><a style="cursor:pointer;">Procedimiento armado Front®</a></li>	
         </ul>
                      
          
          <div class="modal fade " id="ModalPDF" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-fluid modal-notify modal-info modal-xl" role="document">
              <!--Content-->
              <div class="modal-content ">
                <!--Header-->
               
          
                <!--Body-->
                <div class="modal-body ">
          
                  <object class="PDFdoc" width="100%" height="550px" type="application/pdf" data="/assets/Catalogos/SistemaFront.pdf"></object>
                </div>
          
                <!--Footer-->
                <div class="modal-footer justify-content-center">
              
                  <a type="button" class="btn btn-outline-info waves-effect" data-dismiss="modal">Cerrar <i class="fas fa-times ml-1"></i></a>
                </div>
              </div>
              <!--/.Content-->
            </div>
          </div>



          <div class="modal fade " id="ModalPDF2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-fluid modal-notify modal-info modal-xl" role="document">
              <!--Content-->
              <div class="modal-content ">
                <!--Header-->
               
          
                <!--Body-->
                <div class="modal-body ">
          
                  <object class="PDFdoc" width="100%" height="550px" type="application/pdf" data="/assets/Catalogos/ProcedimientoArmadoFRONT(FINAL).pdf"></object>
                </div>
          
                <!--Footer-->
                <div class="modal-footer justify-content-center">
              
                  <a type="button" class="btn btn-outline-info waves-effect" data-dismiss="modal">Cerrar <i class="fas fa-times ml-1"></i></a>
                </div>
              </div>
              <!--/.Content-->
            </div>
          </div>


    </div>


    <div class="video">
        <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/Hqh49Gg0yb8" allowfullscreen></iframe>
          </div>
    </div>
</div> 