import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-andamiosmulti-front',
  templateUrl: './andamiosmulti-front.component.html',
  styleUrls: ['./andamiosmulti-front.component.css']
})
export class AndamiosmultiFrontComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
