import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sistemamultifront',
  templateUrl: './sistemamultifront.component.html',
  styleUrls: ['./sistemamultifront.component.css']
})
export class SistemamultifrontComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
