
    <img class="sucursal" src="/assets/iquique.jpg" style="width:100%; "  alt="">


<div class="fondo">   
    <div class="texts">
        <h1>Andecorp Iquique</h1>
        <p><b>&bull; Dirección:</b> Oscar Bonilla 857 <br>
            <b>&bull; Teléfono:</b> (+56) 57 241 48 30 (+56) 57 242 78 33 <br>
            <b>&bull; Celular:</b> +56 9 9541 7611<br>
            <b>&bull; Email:</b> mahumada@andecorp.cl</p>
            <div class="imagen"><img src="/assets/mapas/iquique.png" alt=""></div>

            <p class="localidades"><b> ARICA Y PARINACOTA:</b> Cuya, Putre, Belen. <br> 
               <b> TARAPACA: </b> Alto Hospicio, Pozo almonte, Pica, Coyahuasi, Cerro Colorado, Quebrada Blanca, Patache. </p>
           
    </div>
    
    
    <!--Google map-->
<div id="map-container-google-1" class="z-depth-1-half map-container">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3744.014223560785!2d-70.14242324975415!3d-20.216729986377327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9152140183068b1f%3A0xff65e6eeb8f8d35!2sOscar%20Bonilla%20857%2C%20Iquique%2C%20Tarapac%C3%A1!5e0!3m2!1ses-419!2scl!4v1602114702833!5m2!1ses-419!2scl" frameborder="0"
      style="border:0" allowfullscreen></iframe>
  </div>
  
  <!--Google Maps-->
</div>