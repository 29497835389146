<div class="fondo">   
    <div class="texts animate__animated animate__backInUp animate__slow">
        <h1>Componentes comunes</h1>
        <p>Componentes comunes en andamios Front® y Multi-front®</p>
        <ul>Componentes
            <li data-toggle="modal" data-target="#ModalPDF"><a style="cursor:pointer;">Componentes comunes</a></li>	
         </ul>
        </div>           
          
          <div class="modal fade " id="ModalPDF" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-fluid modal-notify modal-info modal-xl" role="document">
              <!--Content-->
              <div class="modal-content ">
                <!--Header-->
               
          
                <!--Body-->
                <div class="modal-body ">
          
                  <object class="PDFdoc" width="100%" height="550px" type="application/pdf" data="/assets/Catalogos/componentescomunes.pdf"></object>
                </div>
          
                <!--Footer-->
                <div class="modal-footer justify-content-center">
              
                  <a  type="button" class="btn btn-outline-info waves-effect" data-dismiss="modal" >Cerrar <i class="fas fa-times ml-1"></i></a>
                </div>
              </div>
              <!--/.Content-->
            </div>
          </div>

    
</div>
