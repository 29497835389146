<div class="fondo">
  <!-- <div class="menus">
        <a [routerLink]="['/sistemafront']"><div class="one">Sistema front</div></a>
        <a [routerLink]="['/sistemamultifront']"><div class="two">Sistema multi-front</div></a>
    </div> -->
  <div class="titulo">
    <h1>Sistema Multi-Front®</h1>
  </div>

  <div class="container" id="galeria">
    <div class="row">

      <!-- Grid column -->
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/21.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/21.jpg" alt="" data-toggle="modal"
            data-target="#modal1"></a>


      </div>

      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal141" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/multi_202105_a.jpeg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <!-- miniatura -->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/multi_202105_a.jpeg" alt=""
            data-toggle="modal" data-target="#modal141"></a>


      </div>

      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal142" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/multi_202105_b.jpeg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <!-- miniatura -->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/multi_202105_b.jpeg" alt=""
            data-toggle="modal" data-target="#modal142"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal143" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/multi_202105_c.jpeg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <!-- miniatura -->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/multi_202105_c.jpeg" alt=""
            data-toggle="modal" data-target="#modal143"></a>


      </div>

      <!-- //////////////////////////////////////////////////////////////////////// -->






      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/2.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/2.jpg" alt="" data-toggle="modal"
            data-target="#modal2"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal3" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/3.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/3.jpg" alt="" data-toggle="modal"
            data-target="#modal3"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal4" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/4.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/4.jpg" alt="" data-toggle="modal"
            data-target="#modal4"></a>


      </div>

      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal5" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/5.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/5.jpg" alt="" data-toggle="modal"
            data-target="#modal5"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal6" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/6.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/6.jpg" alt="" data-toggle="modal"
            data-target="#modal6"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal7" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/7.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/7.jpg" alt="" data-toggle="modal"
            data-target="#modal7"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal8" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/8.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/8.jpg" alt="" data-toggle="modal"
            data-target="#modal8"></a>


      </div>

      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal9" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/9.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/9.jpg" alt="" data-toggle="modal"
            data-target="#modal9"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal10" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/10.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/10.jpg" alt="" data-toggle="modal"
            data-target="#modal10"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal11" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/11.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/11.jpg" alt="" data-toggle="modal"
            data-target="#modal11"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal12" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/12.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/12.jpg" alt="" data-toggle="modal"
            data-target="#modal12"></a>


      </div>

      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal13" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/13.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/13.jpg" alt="" data-toggle="modal"
            data-target="#modal13"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal14" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/14.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/14.jpg" alt="" data-toggle="modal"
            data-target="#modal14"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal15" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/15.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/15.jpg" alt="" data-toggle="modal"
            data-target="#modal15"></a>


      </div>


      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal16" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/16.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/16.jpg" alt="" data-toggle="modal"
            data-target="#modal16"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal17" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/17.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/17.jpg" alt="" data-toggle="modal"
            data-target="#modal17"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal18" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/18.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/18.jpg" alt="" data-toggle="modal"
            data-target="#modal18"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal19" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/19.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/19.jpg" alt="" data-toggle="modal"
            data-target="#modal19"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal20" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/20.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/20.jpg" alt="" data-toggle="modal"
            data-target="#modal20"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal21" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/1.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/1.jpg" alt="" data-toggle="modal"
            data-target="#modal21"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal22" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/22.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/22.jpg" alt="" data-toggle="modal"
            data-target="#modal22"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal23" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/23.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/23.jpg" alt="" data-toggle="modal"
            data-target="#modal23"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal24" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/24.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/24.jpg" alt="" data-toggle="modal"
            data-target="#modal24"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal41" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/41.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/41.jpg" alt="" data-toggle="modal"
            data-target="#modal41"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal25" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/25.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/25.jpg" alt="" data-toggle="modal"
            data-target="#modal25"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal26" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/26.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/26.jpg" alt="" data-toggle="modal"
            data-target="#modal26"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal27" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/27.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/27.jpg" alt="" data-toggle="modal"
            data-target="#modal27"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal28" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/28.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/28.jpg" alt="" data-toggle="modal"
            data-target="#modal28"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal29" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/29.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/29.jpg" alt="" data-toggle="modal"
            data-target="#modal29"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal30" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/30.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/30.jpg" alt="" data-toggle="modal"
            data-target="#modal30"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal31" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/31.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/31.jpg" alt="" data-toggle="modal"
            data-target="#modal31"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal32" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/32.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/32.jpg" alt="" data-toggle="modal"
            data-target="#modal32"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal33" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/33.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/33.jpg" alt="" data-toggle="modal"
            data-target="#modal33"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal34" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/34.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/34.jpg" alt="" data-toggle="modal"
            data-target="#modal34"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal35" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/35.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/35.jpg" alt="" data-toggle="modal"
            data-target="#modal35"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal36" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/36.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/36.jpg" alt="" data-toggle="modal"
            data-target="#modal36"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal37" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/37.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/37.jpg" alt="" data-toggle="modal"
            data-target="#modal37"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal38" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/38.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/38.jpg" alt="" data-toggle="modal"
            data-target="#modal38"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal39" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/39.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/39.jpg" alt="" data-toggle="modal"
            data-target="#modal39"></a>


      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">

        <!--Modal: Name-->
        <div class="modal fade" id="modal40" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl" role="document">

            <!--Content-->
            <div class="modal-content">

              <!--Body-->
              <div class="modal-body mb-0 p-0">

                <img src="/assets/proyectosmultiFront/40.jpg" alt="" class="img-fluid pt-2">

              </div>

              <!--Footer-->
              <div class="modal-footer justify-content-center">

                <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4"
                  data-dismiss="modal">Cerrar</button>

              </div>

            </div>
            <!--/.Content-->

          </div>
        </div>
        <!--Modal: Name-->

        <a><img class="img-fluid2 z-depth-1" src="/assets/proyectosmultiFront/40.jpg" alt="" data-toggle="modal"
            data-target="#modal40"></a>


      </div>
      <!--  ///////////////////////////////////////////////////////////////////////////////////////////////// -->





    </div>
  </div>



  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">

      <div class="carousel-item active">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/21.jpg" alt="First slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/2.jpg" alt="Second slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/3.jpg" alt="Third slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/4.jpg" alt="Four slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/5.jpg" alt="Four slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/6.jpg" alt="Four slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/7.jpg" alt="Four slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/8.jpg" alt="Four slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/9.jpg" alt="Four slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/10.jpg" alt="Four slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/proyectosmultiFront/11.jpg" alt="Four slide" height="412" width="375">
        <div class="carousel-caption d-none d-md-block">
        </div>
      </div>

    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>

    <div class="titulomovil">Sistema Multi-FRONT®</div>

  </div>





  <div class="marcas">
    <div class="text">
      <div class="caja">
        Empresas que han confiado en nosotros
      </div>
    </div>
    <div class="gallery">
      <img src="/assets/Logos/01LOGOCHAVO.jpg" alt="">
      <img src="/assets/Logos/02ARC.jpg" alt="">

      <img src="/assets/Logos/03CAVCO.png" alt="">
      <img src="/assets/Logos/04LogoCCMS.png" alt="">
      <img src="/assets/Logos/05LogoConstructoraInmobiliariaGPR.jpg" alt="">

      <img src="/assets/Logos/06LogoConstructoraLN.png" alt="">
      <img src="/assets/Logos/07LOGO IAELECTRIC.png" alt="">
      <img src="/assets/Logos/08logo_abn_alta_OK.jpg" alt="">
      <img src="/assets/Logos/09LogoCelplan.png" alt="">

      <img src="/assets/Logos/11LOGOchc-01.png" alt="">
      <img src="/assets/Logos/12LogoCONSTRUCAM.png" alt="">
      <img src="/assets/Logos/13logocontecnica.png" alt="">
      <img src="/assets/Logos/14LogoEBCO.png" alt="">

      <img src="/assets/Logos/15LOGOINGEVAL.png" alt="">
      <img src="/assets/Logos/16LOGOJOMAR.jpeg" alt="">
      <img src="/assets/Logos/17LogoManzano.png" alt="">
      <img src="/assets/Logos/18logo-vertical-2.png" alt="">
      <img src="/assets/Logos/19SMI.jpg" alt="">
    </div>
  </div>

</div>