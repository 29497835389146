<img class="sucursal" src="/assets/concepcion.jpg" style="width:100%; "  alt="">

<div class="fondo">  
    <div class="texts">
        <h1>Andecorp Concepcion</h1>
        <p><b>&bull; Dirección:</b> Jaime Repullo #1555 - Parque Industrial, Talcahuano<br>
            <b>&bull; Teléfono:</b> (+56) 41259 69 59 <br>
            <b>&bull; Celular:</b> (+56) 9 9454 8679 <br>
            <b>&bull; Email:</b> concepcion@andecorp.cl</p>
            <div class="imagen"><img src="/assets/mapas/concepcion.png" alt=""></div>

            <p class="localidades"><b> BIOBIO:</b> Arauco, Laja, Santa Fe, Nacimiento, Chillan, Los Angeles, Gran Concepcion. <br> 
                <b> LA ARAUCANÍA: </b> Temuco, Pucon, Villarica. <br>
                

            </p>
    </div>

    <!--Google map-->
<div id="map-container-google-1" class="z-depth-1-half map-container">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3196.617804355944!2d-73.0814793848567!3d-36.75574397995776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9669b5532c0431dd%3A0x914a3a6bb86f1575!2sJaime%20Repullo%201555%2C%20Talcahuano%2C%20B%C3%ADo%20B%C3%ADo!5e0!3m2!1ses!2scl!4v1604349068355!5m2!1ses!2scl" frameborder="0"
      style="border:0" allowfullscreen></iframe>
  </div>
  
  <!--Google Maps-->


</div>
