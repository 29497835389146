<div class="fondo">   
    <div class="texts animate__animated animate__backInUp animate__slow">
        <h1>Aplicaciones</h1>
        <p>Aplicaciones de sistemas andamios Multi-front® y Front®</p>
        <ul>Componentes
            <li><a [routerLink]="['/galeriamultifront']" style="cursor:pointer;">Aplicaciones sistema de andamios Multi-Front®</a></li>
            <li><a [routerLink]="['/galeriafront']"  style="cursor:pointer;">Aplicaciones sistema de andamios Front®</a></li>		
         </ul>
    </div>
</div>
