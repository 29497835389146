<div class="fondo">

    <div class="texts animate__animated animate__backInLeft animate__slow">
        <h1>Certificaciones</h1>

    <ul><a [routerLink]="['/certificaciones']">▪ &nbsp; Certificaciones</a>
    </ul>

    <ul><a [routerLink]="['/seguridad']">▪ &nbsp; Seguridad y Flexibilidad</a>  
    </ul>

    <ul>
        <a [routerLink]="['/productoscertificados']">▪ &nbsp; Certificados de Calculo</a>
    </ul>

  

    </div>
    <div class="imgs">
        <img src="/assets/fondoquienessomos.png" alt="">
    </div>
    </div>

