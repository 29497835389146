import { Component, OnInit } from '@angular/core';
import { request } from 'http';

var turn = require ("../../assets/turn.js");


@Component({
  selector: 'app-revista',
  templateUrl: './revista.component.html',
  styleUrls: ['./revista.component.css']
})
export class RevistaComponent implements OnInit {
  

  constructor() { }

  ngOnInit(): void {
  }

}
