<div class="fondo">       
    <div class="texts">
        <h1>Alzaprimas ®</h1>
        <p>Elemento vertical que se utiliza como sistema de moldajes en estructuras temporales de losas de hormigón fresco y sobre carga de personas, equipos, etc. <br>
            Su objetivo es controlar la posición del hormigón, alinear, moldear y dar tamaño a la forma deseada. Conveniente para todo tipo de apuntalamiento. Práctico, económico y resistente. <br>
            En sus dos versiones, las alzaprimas Andecorp satisfacen las más variadas necesidades.
        </p>
        <ul>Alzaprima corta ®
            <li>Desarrollo mínimo: 2.00 mts.</li>
            <li>Desarrollo máximo: 3.40 mts.</li>
        </ul>
        <ul>Alzaprima larga ®
            <li>Desarrollo mínimo: 2.96 mts.</li>
            <li>Desarrollo máximo: 5.00 mts.</li>
        </ul>
    </div>
    <div class="imgs">
        <img src="/assets/Alzaprimas.png" alt="">
    </div>
</div>