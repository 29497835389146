<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active d-none d-md-block"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1" class=" d-none d-md-block"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2" class=" d-none d-md-block"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3" class=" d-none d-md-block"></li>
    </ol>
    <div class="carousel-inner">

      <div class="carousel-item active" data-interval="10000">
        <img class="d-block w-100" src="/assets/f001.jpeg" alt="First slide">
        <div class="carousel-caption d-none d-md-block">
            <h5><b>Sistemas de andamiajes FRONT® fachadas</b></h5>
            <p>Contando con un conjunto de elementos prefabricados de acero como marcos, barandas, diagonales, plataformas y una completa gama de componente auxiliares el sistema de andamiajes <b>FRONT®</b> es ideal para cubrir grandes extensiones de superficie siendo perfecto para trabajos de fachada (construcción, pinturas y retoques) incluso en alturas irregulares.</p>
          </div>
          <div class="texto d-md-none">
            <div class="textoUno">
              Sistemas de andamiajes FRONT® fachadas
            </div>
            <div class="textoDos">
              Contando con un conjunto de elementos prefabricados de acero como marcos, barandas, diagonales, plataformas y una completa gama de componente auxiliares el sistema de andamiajes <b>FRONT®</b> es ideal para cubrir grandes extensiones de superficie siendo perfecto para trabajos de fachada (construcción, pinturas y retoques) incluso en alturas irregulares.
            </div>
          </div>
      </div>
      
      <div class="carousel-item" data-interval="10000">
        <img class="d-block w-100" src="/assets/f02.png" alt="Second slide">
        <div class="carousel-caption d-none d-md-block">
          <h5><b>Sistemas de andamiajes MULTIFRONT® torres</b></h5>
          <p>Las características modulares del andamio <b>MULTRIFRONT®</b> lo hacen ideal para dar soluciones a los trabajadores en la altura donde el espacio a cubrir es reducido. También de esta forma puede ser utilizado creando soluciones varias para así satisfacer las más amplias necesidades.</p>
        </div>
        <div class="texto d-md-none">
          <div class="textoUno">
            Sistemas de andamiajes MULTIFRONT® torres
          </div>
          <div class="textoDos">
            Las características modulares del andamio <b>MULTRIFRONT®</b> lo hacen ideal para dar soluciones a los trabajadores en la altura donde el espacio a cubrir es reducido. También de esta forma puede ser utilizado creando soluciones varias para así satisfacer las más amplias necesidades.
          </div>
        </div>
      </div>

      <div class="carousel-item" data-interval="10000">
        <img class="d-block w-100" src="/assets/f003.jpeg" alt="Third slide">
        <div class="carousel-caption d-none d-md-block">
          <h5><b>Sistemas de andamiajes MULTIFRONT® estanques</b></h5>
          <p>Aplicado en estanques, torres y chimeneas, el andamio MULTRIFRONT® brinda seguridad al rodear las superficies a trabajar con eficacia logrando así la mejor disposición, permitiendo recorridos ágiles y accesos seguros en cualquier lugar, no importando así la envergadura de la obra.</p>
        </div>
        <div class="texto d-md-none">
          <div class="textoUno">
            Sistemas de andamiajes MULTIFRONT® estanques
          </div>
          <div class="textoDos">
            Aplicado en estanques, torres y chimeneas, el andamio MULTRIFRONT® brinda seguridad al rodear las superficies a trabajar con eficacia logrando así la mejor disposición, permitiendo recorridos ágiles y accesos seguros en cualquier lugar, no importando así la envergadura de la obra.
          </div>
        </div>
      </div>
      
      <div class="carousel-item" data-interval="10000">
        <img class="d-block w-100" src="/assets/f04.png" alt="Four slide">
        <div class="carousel-caption d-none d-md-block">
          <h5><b>Torres peatonales MULTIFRONT®</b></h5>
          <p>Especialmente diseñada para un transito expedito tanto en trabajos en altura como en descensos. Puede ser aplicada ampliamente en fosos, trabajos de minería y alturas varias.</p>
        </div>
        <div class="texto d-md-none">
          <div class="textoUno">
            Torres peatonales MULTIFRONT®
          </div>
          <div class="textoDos">
            Especialmente diseñada para un transito expedito tanto en trabajos en altura como en descensos. Puede ser aplicada ampliamente en fosos, trabajos de minería y alturas varias.
          </div>
        </div>


      </div>
      
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
