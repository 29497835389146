<div class="fondo">

    <div class="texts animate__animated animate__backInLeft animate__slow">
        <h1>Nosotros</h1>

    <ul><a [routerLink]="['/quienessomos']">▪ &nbsp; Quiénes Somos</a>
    </ul>

    <ul><a [routerLink]="['/politicadecalidad']">▪ &nbsp; Politica de Calidad</a>  
    </ul>

    <ul>
        <a [routerLink]="['/satisfaccionalcliente']">▪ &nbsp; Satisfacción al Cliente</a>
    </ul>

  

    </div>
    <div class="imgs">
        <img src="/assets/fondoquienessomos.png" alt="">
    </div>
    </div>


