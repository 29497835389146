import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-galeriafront',
  templateUrl: './galeriafront.component.html',
  styleUrls: ['./galeriafront.component.css']
})
export class GaleriafrontComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
