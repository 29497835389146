<img class="sucursal" src="/assets/santiago.jpg" style="width:100%; "  alt="">

<div class="fondo">  
    <div class="texts">
        <h1>Andecorp Santiago</h1>
        <p><b>&bull; Dirección:</b> Senador Jaime Guzmán #141, Quilicura<br>
            <b>&bull; Teléfono:</b> (+56) 22 603 9091 (+56) 22 603 64 11 (+56) 22 603 6395 (+56) 22 603 4388 <br>
            <b>&bull; Celular:</b> (+56) 9 9324 4387 <br>
            <b>&bull; Email:</b> ventas@andecorp.cl</p>
            <div class="imagen"><img src="/assets/mapas/santiago.png" alt=""></div>

            <p class="localidades"><b> METROPOLITANA:</b> Toda la RM.  <br> 
                <b> VALPARAISO: </b>  Valparaíso, Los Andes, San Felipe, Litoral central. <br> 
                <b> COQUIMBO: </b>  La Serena, Coquimbo, Ovalle. <br> 
                <b> LIBERTADOR GENERAL BERNARDO O'HIGGINS: </b>  Rancagua,  Minera El Teniente. <br> 
                <b> MAULE: </b>  Curicó, Talca, Linares.
            </p>
    </div>

    <!--Google map-->
<div id="map-container-google-1" class="z-depth-1-half map-container">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3332.169029869417!2d-70.70807848480283!3d-33.36665288079801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c7286e5f4dad%3A0x1434bd59f11b1c63!2sSenador%20Jaime%20Guzm%C3%A1n%20141%2C%20Quilicura%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1604328219366!5m2!1ses-419!2scl" frameborder="0"
      style="border:0" allowfullscreen></iframe>
  </div>
  
  <!--Google Maps-->


</div>
