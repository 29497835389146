import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlzaprimasComponent } from './alzaprimas/alzaprimas.component';
import { AndamiosfrontComponent } from './andamiosfront/andamiosfront.component';
import { AndamiosmultiFrontComponent } from './andamiosmulti-front/andamiosmulti-front.component';
import { AntofagastaComponent } from './antofagasta/antofagasta.component';
import { AplicacionesComponent } from './aplicaciones/aplicaciones.component';
import { CertificacionesHomeComponent } from './certificaciones-home/certificaciones-home.component';
import { CertificacionesComponent } from './certificaciones/certificaciones.component';
import { ComponentescomunesComponent } from './componentescomunes/componentescomunes.component';
import { ConcepcionComponent } from './concepcion/concepcion.component';
import { ContactoComponent } from './contacto/contacto.component';
import { GaleriafrontComponent } from './galeriafront/galeriafront.component';
import { GaleriamultifrontComponent } from './galeriamultifront/galeriamultifront.component';
import { InicioComponent } from './inicio/inicio.component';
import { IquiqueComponent } from './iquique/iquique.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { NuestrosproductosComponent } from './nuestrosproductos/nuestrosproductos.component';
import { PoliticadecalidadComponent } from './politicadecalidad/politicadecalidad.component';
import { ProductoscertificadosComponent } from './productoscertificados/productoscertificados.component';
import { ProximamenteComponent } from './proximamente/proximamente.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { PuertomonttComponent } from './puertomontt/puertomontt.component';
import { QuienessomosComponent } from './quienessomos/quienessomos.component';
import { RevistaComponent } from './revista/revista.component';
import { acertComponent } from './acert/acert.component';
import { SantiagoComponent } from './santiago/santiago.component';
import { SatisfaccionalclienteComponent } from './satisfaccionalcliente/satisfaccionalcliente.component';
import { SeguridadComponent } from './seguridad/seguridad.component';
import { SistemafrontComponent } from './sistemafront/sistemafront.component';
import { SistemamultifrontComponent } from './sistemamultifront/sistemamultifront.component';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { TalcaComponent } from './talca/talca.component';
import { ValparaisoComponent } from './valparaiso/valparaiso.component';

const routes: Routes = [
  
  
  {path: "inicio", component: InicioComponent},
  {path: "quienessomos", component: QuienessomosComponent},
  {path: "politicadecalidad", component: PoliticadecalidadComponent},
  {path: "satisfaccionalcliente", component: SatisfaccionalclienteComponent},
  {path: "nuestrosproductos", component: NuestrosproductosComponent},
  {path: "andamiosmulti-front", component: AndamiosmultiFrontComponent},
  {path: "andamiosfront", component: AndamiosfrontComponent},
  {path: "alzaprimas", component: AlzaprimasComponent},
  {path: "sucursales", component: SucursalesComponent},
  {path: "iquique", component: IquiqueComponent},
  {path: "antofagasta", component: AntofagastaComponent},
  {path: "certificaciones", component: CertificacionesComponent},
  {path: "seguridad", component: SeguridadComponent}, 
  {path: "sistemafront", component: SistemafrontComponent},
  {path: "sistemamultifront", component: SistemamultifrontComponent},
  {path: "contacto", component: ContactoComponent},
  {path: "santiago", component: SantiagoComponent},
  {path: "concepcion", component: ConcepcionComponent},
  {path: "puertomontt", component: PuertomonttComponent},
  {path: "componentescomunes", component: ComponentescomunesComponent},
  {path: "aplicaciones", component: AplicacionesComponent},
  {path: "galeriamultifront", component: GaleriamultifrontComponent},
  {path: "galeriafront", component: GaleriafrontComponent},
  {path: "productoscertificados", component: ProductoscertificadosComponent},
  {path: "valparaiso", component: ValparaisoComponent},
  {path: "talca", component: TalcaComponent},
  {path: "proximamente", component: ProximamenteComponent},
  {path: "nosotros", component: NosotrosComponent},
  {path: "proyectos", component: ProyectosComponent},
  {path: "certificaciones-home", component: CertificacionesHomeComponent},
  {path: "revista", component: RevistaComponent},
  {path: "acert", component: acertComponent},
    {path: "**", component: InicioComponent}

];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
