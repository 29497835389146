<!-- <div class="fondo">

    <div class="texts">
        <h1>Productos Certificados</h1>
        <p> Andecorp S.P.A. empresa chilena establecida en 1993 y certificada ISO 9000:2008 desde el año 2011, dedicada principalmente a la fabricación, venta, importación y arriendo de Sistemas de Andamiajes y Apuntalamiento, para el mercado Nacional y Extranjero. <br><br>
            El mercado objetivo natural de nuestros productos, son las empresas constructoras en general, empresas de servicios que operen en altura, empresas de montaje industrial, empresas mineras, montaje de eventos, entre otras. <br><br>
             Nuestra capacidad de producción nos permite abastecer el mercado nacional y estar en condiciones de exportar a precios altamente competitivos.</p>
    </div>
    <div class="imgs">
        <img src="/assets/fondoquienessomos.png" alt="">
    </div>
    </div> -->
<!--
    <div class="fondo">
        <iframe src="./web2/acert.php" height="600" width=100%  name="miandecorp" title="Mi Andecorp" ></iframe>
    </div>
-->


<div class="fondo">
    <iframe src="./code/code.php" height="600" width=100%  title="Mi Andecorp" name="codigo" ></iframe>
</div>