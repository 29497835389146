import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicadecalidad',
  templateUrl: './politicadecalidad.component.html',
  styleUrls: ['./politicadecalidad.component.css']
})
export class PoliticadecalidadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
