<div class="fondo"> 
    <!-- <div class="menus">
        <a [routerLink]="['/certificaciones']"><div class="one">Certificaciones</div></a>
        <a [routerLink]="['/seguridad']"><div class="two">Seguridad</div></a>
    </div> -->
    <div class="texts animate__animated animate__backInUp animate__slow">
        <h1>Seguridad y Flexibilidad</h1>
        <p>El sistema de enganche de las plataformas permite desmotar parcialmente el andamio, recuperando las plataformas sin necesidad de desmontar los marcos del andamio. <br><br>
            De esta forma es posible desmontar una columna de andamios y volver a armarlo en otro lugar del edificio. <br><br>
            Asimismo una vez colocadas las plataformas, se obtiene una superficie de trabajo sin obstáculos, que permite brindar más seguridad a los operarios. <br><br>
            Plataformas con escalas permiten acceder a los distintos niveles de trabajo, en forma rápida y segura.</p>
    </div>
    <div class="imgs">
        <img src="/assets/collage-seguridad-flexibilidad.jpg" alt="">
    </div>
</div>