import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iquique',
  templateUrl: './iquique.component.html',
  styleUrls: ['./iquique.component.css']
})
export class IquiqueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
