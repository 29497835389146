<div class="fondo">   
    <!-- <div class="menus">
        <a [routerLink]="['/quienessomos']"><div class="one">Quiénes somos</div></a>
        <a [routerLink]="['/politicadecalidad']"><div class="two">Política de calidad</div></a>
        <a [routerLink]="['/satisfaccionalcliente']"><div class="three">Satisfacción al cliente</div></a>
    </div> -->
    <div class="texts animate__animated animate__backInUp animate__slow ">
        <h1>Política de calidad</h1>
        <p>Nuestra empresa considera como parte fundamental de su negocio y asume como compromiso, la permanente preocupación por fabricar nuestros productos con las mejores materias primas, insumos y productos de terminación. <br><br> 
            Asimismo como la constante capacitación y la motivación de los recursos humanos, para que se involucren de mejor manera en el proceso productivo y así poder cumplir con los requisitos de fabricación que nos permitan llevar a cabo los objetivos propuestos. <br><br>
            De la misma manera procurar y asegurar el cumplimiento de todos los requisitos legales, aplicables a las operaciones de la empresa.  </p>
    </div>
    <div class="imgs">
        <img src="/assets/certificado1.png" alt="">
        <img src="/assets/certificado2.png" alt="">
    </div>
</div>