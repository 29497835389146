<div class="fondo">
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
        <div class="carousel-item active" data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_001.png"  alt="First slide">
        </div>
        <div class="carousel-item" data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_002.png" alt="Second slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_003.png" alt="Third slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_004.png"  alt="First slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_005.png" alt="Second slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_006.png" alt="Third slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_007.png"  alt="First slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_008.png" alt="Second slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_009.png" alt="Third slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_010.png"  alt="First slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_011.png" alt="Second slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_012.png" alt="Third slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_013.png"  alt="First slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_014.png" alt="Second slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_015.png" alt="Third slide">
        </div>
        <div class="carousel-item"data-interval="100000">
            <img class="d-block w-100" src="/assets/CatalogoV6Final/CatalogoV6Final_016.png" alt="Third slide">
        </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
        </a>
    </div>
    <div class="mt-2 mb-2">
        <a href="/assets/CatalogoV6Final/CatalogoV6Final.pdf">Descarga Catalogo</a>
    </div>
    

</div>