<img class="sucursal" src="/assets/antofagasta.jpg" style="width:100%; "  alt="">

<div class="fondo">  
    <div class="texts">
        <h1>Andecorp Antofagasta</h1>
        <p><b>&bull; Dirección:</b> Pedro Aguirre Cerda 7309 – Barrio Industrial <br>
            <b>&bull; Teléfono:</b> (+56) 55 227 07 81 (+56) 55 227 07 04 <br>
            <b>&bull; Celular:</b> +56 96 839 73 10 <br>
            <b>&bull; Email:</b> antofagasta@andecorp.cl</p>
            <div class="imagen"><img src="/assets/mapas/antofagasta.png" alt=""></div>

            <p class="localidades"><b> ANTOFAGASTA:</b> Calama, Tocopilla, Maria Elena, Taltal (Minera Cenizas), Mejillones, San Pedro De Atacama, Minera Escondida - Altonorte - Albermarle, etc. <br> 
                <b> ATACAMA: </b> Copiapó. </p>
    </div>

    <!--Google map-->
<div id="map-container-google-1" class="z-depth-1-half map-container">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.0492025195117!2d-70.38920965015737!3d-23.60256829873124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96ae2ade84b277b7%3A0x9f892921cf695af2!2sAv.%20Pedro%20Aguirre%20Cerda%207309%2C%20Antofagasta!5e0!3m2!1ses-419!2scl!4v1602115577814!5m2!1ses-419!2scl" frameborder="0"
      style="border:0" allowfullscreen></iframe>
  </div>
  
  <!--Google Maps-->


</div>