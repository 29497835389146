<div class="fondo">   
    <!-- <div class="menus">
        <a [routerLink]="['/quienessomos']"><div class="one">Quiénes somos</div></a>
        <a [routerLink]="['/politicadecalidad']"><div class="two">Política de calidad</div></a>
        <a [routerLink]="['/satisfaccionalcliente']"><div class="three">Satisfacción al cliente</div></a>
    </div> -->
    <div class="texts animate__animated animate__backInUp animate__slow">
        <h1>Satisfacción al cliente</h1>
        <p>Teniendo presente los buenos resultados obtenidos en nuestra polí­tica de calidad, polí­tica que mantendrá sus niveles de exigencia, es que abordamos como un nuevo desafí­o el aumento en los niveles de “Satisfacción del Cliente”. <br><br>
            Para esto consideraremos tres aspectos concretos: <br>
            1.- Mejorar la atención del cliente desde todas las áreas involucradas en la gestión comercial. <br>
            2.- Mejorar los tiempos de respuesta para los requerimientos de nuestros clientes. <br>
            3.- La capacitación en el uso de nuestros equipos y los riesgos inherentes al uso de los mismos. <br>
            Estos tres aspectos tienen como finalidad consolidar la confianza de nuestros clientes para así­ lograr su máxima “Fidelización”.</p>
    </div>
    <div class="imgs">
        <img src="/assets/satisfaccionalcliente.jpeg" alt="">
    </div>
</div>
