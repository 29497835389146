import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-galeriamultifront',
  templateUrl: './galeriamultifront.component.html',
  styleUrls: ['./galeriamultifront.component.css']
})
export class GaleriamultifrontComponent implements OnInit {

  constructor() {
   
   }

  ngOnInit(): void {
    
  }

}
