<div class="container" id="galeria">
<div class="row">

    <!-- Grid column -->
    <div class="col-lg-3 col-md-6 col-sm-12">
  
      <!--Modal: Name-->
      <div class="modal fade" id="modal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
  
          <!--Content-->
          <div class="modal-content">
  
            <!--Body-->
            <div class="modal-body mb-0 p-0">
  
                <img src="/assets/front/front_001.png" alt=""  width="100%">
  
            </div>
  
            <!--Footer-->
            <div class="modal-footer justify-content-center">
  
              <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>
  
            </div>
  
          </div>
          <!--/.Content-->
  
        </div>
      </div>
      <!--Modal: Name-->
  
      <a><img class="img-fluid z-depth-1" src="/assets/front/front_001.png" alt=""
          data-toggle="modal" data-target="#modal1"></a>
  
   
  </div>
 


  <div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_002.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_002.png" alt=""
        data-toggle="modal" data-target="#modal2"></a>

 
</div>


<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal3" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_003.png" alt=""  width="100%" >

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_003.png" alt=""
        data-toggle="modal" data-target="#modal3"></a>

 
</div>


<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal4" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_004.png" alt=""  width="100%" >

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_004.png" alt=""
        data-toggle="modal" data-target="#modal4"></a>

 
</div>


<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal5" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_005.png" alt=""  width="100%" >

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_005.png" alt=""
        data-toggle="modal" data-target="#modal5"></a>

 
</div>


<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal6" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_006.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_006.png" alt=""
        data-toggle="modal" data-target="#modal6"></a>

 
</div>


<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal7" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_007.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_007.png" alt=""
        data-toggle="modal" data-target="#modal7"></a>

 
</div>


<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal8" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_008.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_008.png" alt=""
        data-toggle="modal" data-target="#modal8"></a>

 
</div>


<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal10" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_009.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_009.png" alt=""
        data-toggle="modal" data-target="#modal10"></a>

 
</div>


<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal11" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_010.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_010.png" alt=""
        data-toggle="modal" data-target="#modal11"></a>

 
</div>

<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal12" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_011.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_011.png" alt=""
        data-toggle="modal" data-target="#modal12"></a>

 
</div>

<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal13" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_012.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_012.png" alt=""
        data-toggle="modal" data-target="#modal13"></a>

 
</div>

<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal14" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_013.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_013.png" alt=""
        data-toggle="modal" data-target="#modal14"></a>

 
</div>

<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal15" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_014.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_014.png" alt=""
        data-toggle="modal" data-target="#modal15"></a>

 
</div>

<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal16" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_015.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_015.png" alt=""
        data-toggle="modal" data-target="#modal16"></a>

 
</div>

<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal17" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_016.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_016.png" alt=""
        data-toggle="modal" data-target="#modal17"></a>

 
</div>

<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal18" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_017.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_017.png" alt=""
        data-toggle="modal" data-target="#modal18"></a>

 
</div>

<div class="col-lg-3 col-md-6 col-sm-12">
  
    <!--Modal: Name-->
    <div class="modal fade" id="modal19" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">

        <!--Content-->
        <div class="modal-content">

          <!--Body-->
          <div class="modal-body mb-0 p-0">

              <img src="/assets/front/front_018.png" alt=""  width="100%">

          </div>

          <!--Footer-->
          <div class="modal-footer justify-content-center">

            <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Cerrar</button>

          </div>

        </div>
        <!--/.Content-->

      </div>
    </div>
    <!--Modal: Name-->

    <a><img class="img-fluid z-depth-1" src="/assets/front/front_018.png" alt=""
        data-toggle="modal" data-target="#modal19"></a>

 
</div>
</div>
</div>