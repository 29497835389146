<img class="sucursal" src="/assets/puertomontt.jpeg" style="width:100%; "  alt="">

<div class="fondo">  
    <div class="texts">
        <h1>Andecorp Puerto Montt</h1>
        <p><b>&bull; Dirección:</b> Inmaculada #941 - Parque Industrial Recondo<br>
            <b>&bull; Teléfono:</b> (+56) 65 231 9275 (+56) 65 234 74 71 <br>
            <b>&bull; Celular:</b> (+56) 9 9249 5314 <br>
            <b>&bull; Email:</b> puertomontt@andecorp.cl mbaez@andecorp.cl</p>
            <div class="imagen"><img src="/assets/mapas/puertomontt.png" alt=""></div>

            <p class="localidades">
                <b> LOS LAGOS:</b> Osorno, Frutillar, Puerto Varas, Llanquihue, Isla de Chiloe (Ancud, Castro, Quellon, Chonchi). <br> 
                <b> LOS RÍOS: </b> Valdivia. <br>
                <b> AYSÉN DEL GENERAL CARLOS IBÁÑEZ DEL CAMPO: </b> Coyhaique. <br>
                <b> MAGALLANES Y DE LA ANTÁRTICA CHILENA: </b> Puerto Natales. <br>
                

            </p>
    </div>

    <!--Google map-->
<div id="map-container-google-1" class="z-depth-1-half map-container">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11959.965350979262!2d-72.9734287!3d-41.4611032!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x840980ab6db459a3!2sAndesCorp!5e0!3m2!1ses-419!2scl!4v1606138248729!5m2!1ses-419!2scl" frameborder="0"
      style="border:0" allowfullscreen></iframe>
  </div>
  
  <!--Google Maps-->


</div>

