<div class="fondo animate__animated  animate__slow  animate__fadeInUp">  
<h1>Contacto</h1>
<p>Queremos saber de ti. Envíanos tus datos, consultas y te responderemos a la brevedad. <br>
    <a style="color:red;"> * Campos obligatorios</a></p>
    <form method="post">
        <div class="form-row">
            <div class="mitad1">
                <div class="form-group col-md-11 was-validated">
                    <label for="inputNombre">Nombre completo <a style="color:red;">*</a></label>
                    <input type="text" class="form-control is-invalid" id="nombre" name="user_name" aria-describedby="validatedInputGroupPrepend" required>
                </div>
                <div class="form-group col-md-11 was-validated ">
                    <label for="inputRazonSocial">Razón social <a style="color:red;">*</a></label>
                    <input type="text" class="form-control is-invalid" id="razonsocial" name="user_razonsocial" aria-describedby="validatedInputGroupPrepend" required>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="form-group col-md-5 was-validated ">
                        <label for="inputRut">RUT empresa <a style="color:red;">*</a></label>
                        <input type="text" class="form-control is-invalid" id="rut" name="user_rut" aria-describedby="validatedInputGroupPrepend" required>
                    </div>
                    
                    <div class="form-group col-md-5 was-validated mr-5">
                        <label for="inputNombreObra">Nombre de obra <a style="color:red;">*</a></label>
                        <input type="text" class="form-control" id="nombreObra" name="user_nombreObra" aria-describedby="validatedInputGroupPrepend" required>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="form-group col-md-5 was-validated ">
                        <label for="inputRut">Correo electrónico<a style="color:red;">*</a></label>
                        <input type="email" class="form-control is-invalid" id="rut" name="user_rut" aria-describedby="validatedInputGroupPrepend" required>
                    </div>
                    
                    <div class="form-group col-md-5 mr-5">
                        <label for="inputNombreObra">Teléfono/celular</label>
                        <input type="text" class="form-control" id="nombreObra" name="user_nombreObra">
                    </div>
                </div>
            </div>
            <div class="mitad2">
                <div class="form-group col-md-11 was-validated ">
                    <label for="inputRegion">Región/localidad <a style="color:red;">*</a></label>
                    <input type="text" class="form-control" id="region" name="user_region" aria-describedby="validatedInputGroupPrepend" required>
                </div> 
                <div class="form-group col-md-7 " >
                    <label for="inputAsunto">Asunto</label>
                    <input type="text" class="form-control" id="asunto" name="user_asunto">
                </div> 

                <div class="form-group col-md-11">
                    <label for="exampleFormControlTextarea1">Tu mensaje</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="4"></textarea>
                </div>
                <button type="submit" class="btn btn-warning">Enviar</button>
            </div>
        </div>
    </form>
</div>