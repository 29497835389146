<div class="fondo">

    <div class="texts animate__animated animate__backInLeft animate__slow">
        <h1>Proyectos</h1>

    <ul><a [routerLink]="['/sistemafront']">▪ &nbsp; Sistema Front ®</a>
    </ul>

    <ul><a [routerLink]="['/sistemamultifront']">▪ &nbsp; Sistema Multi-Front ®</a>  
    </ul>

  

    </div>
    <div class="imgs">
        <img src="/assets/fondoquienessomos.png" alt="">
    </div>
    </div>

