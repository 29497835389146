<div class="fondo">
    <div class="mapaChile animate__animated  animate__slow animate__flip">
        <p>Más cerca de nuestros clientes</p>
        <img src="/assets/mapas/ANDECORPMAPA_001.png">
    </div>    
    <section id="galeria">
        <div class="row">
            
            <div [routerLink]="['/iquique']" class="col-lg-4 col-md-6 col-sm-12" >
                <img src="/assets/iquique.jpg" style="width:100%; "  alt="">
                <div class="texto"><h4>Andecorp Iquique <br> Región de Tarapacá</h4></div>
            </div>
            
            
            <div [routerLink]="['/antofagasta']" class="col-lg-4 col-md-6 col-sm-12">
                <img src="/assets/antofagasta.jpg" style="width:100%; "  alt="">
                <div class="texto"><h4>Andecorp Antofagasta <br> Región de Antofagasta</h4></div>
            </div>
            
            <div [routerLink]="['/valparaiso']" class="col-lg-4 col-md-6 col-sm-12">
                <img src="/assets/puchuncavi.png" style="width:100%; "  alt="">
                <div class="texto"><h4>Andecorp Valparaiso <br> Región de Valparaíso</h4></div>
            </div>

            <div [routerLink]="['/santiago']" class="col-lg-4 col-md-6 col-sm-12">
                <img src="/assets/santiago.jpg" style="width:100%; "  alt="">
                <div class="texto"><h4>Andecorp Santiago <br> Región Metropolitana</h4></div>
            </div>
            
            <div [routerLink]="['/talca']" class="col-lg-4 col-md-6 col-sm-12">
                <img src="/assets/proximamente.png" style="width:100%; "  alt="">
                <div class="texto"><h4>Andecorp Talca <br> Región del Maule</h4></div>
            </div>

            <div [routerLink]="['/concepcion']" class="col-lg-4 col-md-6 col-sm-12">
                <img src="/assets/concepcion.png" style="width:100%; "  alt="">
                <div class="texto"><h4>Andecorp Concepcion <br> Región del Biobío</h4></div>
            </div>
            

            <div [routerLink]="['/puertomontt']" class="col-lg-4 col-md-6 col-sm-12">
                <img src="/assets/puertomontt.jpeg" style="width:100%; "  alt="">
                <div class="texto"><h4>Andecorp Puerto Montt <br> Región de Los Lagos</h4></div>
            </div>        

            

        </div>

    </section>  






    <!-- <div class="grid">
        <div class="uno">
            <a [routerLink]="['/iquique']">
            <div class="imagenes">
                <img src="/assets/iquique.jpg" style="width:100%; "  alt="">
            </div>
            <div class="texto"><h4>Andecorp Iquique</h4></div>
            </a>
        </div>

        <div class="dos">
            <a [routerLink]="['/antofagasta']">
            <div class="imagenes">
                <img src="/assets/antofagasta.jpg" style="width:100%; "  alt="">
            </div>
            <div class="texto"><h4>Andecorp Antofagasta</h4></div>
            </a>
        </div>

        <div class="tres">
            <a href="">
            <div class="imagenes">
                <img src="/assets/santiago.jpg" style="width:100%; "  alt="">
            </div>
            <div class="texto"><h4>Andecorp Santiago</h4></div>
            </a>
        </div>

        <div class="cuatro">
            <a href="">
            <div class="imagenes">
                <img src="/assets/concepcion.jpg" style="width:100%; "  alt="">
            </div>
            <div class="texto"><h4>Andecorp Concepcion</h4></div>
            </a>
        </div>

        <div class="cinco">
            <a href="">
            <div class="imagenes">
                <img src="/assets/puertomontt.jpg" style="width:100%; "  alt="">
            </div>
            <div class="texto"><h4>Andecorp Puerto Montt</h4></div> 
            </a> 
        </div>
    </div> -->
</div>


