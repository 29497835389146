import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-componentescomunes',
  templateUrl: './componentescomunes.component.html',
  styleUrls: ['./componentescomunes.component.css']
})
export class ComponentescomunesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
