<div class="fondo">

    <div class="texts animate__animated animate__backInLeft animate__slow">
        <h1>Nuestros productos</h1>

    <ul><a [routerLink]="['/andamiosfront']">▪ &nbsp; Sistema de andamios Front ®</a>
    </ul>

    <ul><a [routerLink]="['/andamiosmulti-front']">▪ &nbsp; Sistema de andamios Multi-Front ®</a>  
    </ul>

    <ul>
        <a [routerLink]="['/componentescomunes']">▪ &nbsp; Componentes comunes</a>
    </ul>

    <ul>
        <a [routerLink]="['/aplicaciones']">▪ &nbsp; Aplicaciones</a>
    </ul>

    <ul>
        <a [routerLink]="['/revista']">▪ &nbsp; Catálogo</a>
    </ul>

    </div>
    <div class="imgs">
        <img src="/assets/fondoquienessomos.png" alt="">
    </div>
    </div>


<div class="fondo2" >
    <div class="titulo"><a>Nuestros productos</a></div>
    <div class="barras" [routerLink]="['/andamiosfront']"><a>Sistema de andamios Front®</a></div>
    <div class="imagenes">
        <img src="/assets/fachadas.png" alt="">
        <div class="texto-encima">
            <a>Fachadas</a> 
        </div>
    </div>
    <div class="barras" [routerLink]="['/andamiosmulti-front']"><a>Sistema de andamios Multi-Front®</a></div>
    <div class="imagenes">
        <img src="/assets/torres.png" alt="">
        <div class="texto-encima">
            <a>Torres</a> 
        </div>
    </div>
    <div class="imagenes-derecha">
        <img src="/assets/estanques.png" alt="">
        <div class="texto-encima">
            <a>Estanques</a> 
        </div> 
    </div>
    <div class="imagenes">
        <img src="/assets/torres.png" alt="">
        <div class="texto-encima">
            <a>Copas de agua</a> 
        </div>
    </div>
    <div class="imagenes-derecha">
        <img src="/assets/torres-peatonales.png" alt="">
        <div class="texto-encima">
            <a>Torres peatonales</a> 
        </div> 
    </div>
    <div class="barras" [routerLink]="['/alzaprimas']"><a>Alzaprimas</a></div>
    <div class="imagenes">
        <img src="/assets/alzaprimasmovil.png" alt="">
        <div class="texto-encima">
            <a>Alzaprimas</a> 
        </div>
    </div>
</div>